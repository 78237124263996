import { PlatformsEnum } from '@shared/platforms/enums/platforms.enum';
import { QaCheckLevel } from '@shared/qa-checklists/models/qa-check-level.model';
import { Type } from 'class-transformer';

export class QaManualCheck {
  id?: number;

  name: string;

  description?: string;

  client: string;

  platform_name: PlatformsEnum;

  @Type(() => QaCheckLevel)
  check_conditions: QaCheckLevel[];
}
