import { Injectable } from '@angular/core';
import { QaChecklist } from '@shared/qa-checklists/models/qa-checklist.model';

@Injectable({
  providedIn: 'root'
})
export class QaService {
  private __qaChecklist: QaChecklist;
  private __showPopupImmediately: boolean = false;

  get qaChecklist(): QaChecklist {
    if (!this.__qaChecklist) {
      const storedChecklist = localStorage.getItem('qaChecklist');
      if (storedChecklist) {
        this.__qaChecklist = JSON.parse(storedChecklist) as QaChecklist;
      }
    }
    return this.__qaChecklist;
  }

  get showPopupImmediately(): boolean {
    return this.__showPopupImmediately;
  }

  set qaChecklist(checklist: QaChecklist) {
    this.__qaChecklist = checklist;
    localStorage.setItem('qaChecklist', JSON.stringify(checklist));
  }

  set showPopupImmediately(value: boolean) {
    this.__showPopupImmediately = value;
  }
}
