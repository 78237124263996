import { QaResultProcessStatus } from '@shared/qa-checklists/types/qa-result-process-status.type';
import { QaResultSubmissionStatus } from '@shared/qa-checklists/types/qa-result-submission-status.type';
import { Type } from 'class-transformer';

export class QaTableResult {
  id: number;
  name: string;
  platform_name: string;
  submission_status: QaResultSubmissionStatus;
  process_status: QaResultProcessStatus;
  @Type(() => Date)
  processed_date?: Date;
  user: {
    id: number;
    email: string;
    picture: string;
    name: string;
  };
}
