import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationQuery, PaginationQueryResult } from '@core/models/search-query.model';
import { API_ROUTES } from '@environments/routes/api-routes';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { PaginationService } from '@shared/common/services/pagination.service';
import { NgxTableDatasource } from '@shared/ngx-table/interfaces/ngx-table-datasource.interface';
import { QaResultCheck } from '@shared/qa-checklists/models/qa-result-check.model';
import { QaResultChecksPaginationParams } from '@shared/qa-checklists/models/qa-result-checks-pagination-params.model';
import { QaResultPaginationParams } from '@shared/qa-checklists/models/qa-result-pagination-params.model';
import { QaTableResult } from '@shared/qa-checklists/models/qa-table-result.model';
import { QaResultCheckStatusType } from '@shared/qa-checklists/types/qa-result-check-status.type';
import { QaResultDetails } from '@shared/qa-checklists/types/qa-result-details.type';
import { QaResultPayloadType } from '@shared/qa-checklists/types/qa-result-payload.type';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';

@Injectable()
export class QaResultService {
  constructor(
    private readonly __paginationService: PaginationService,
    private readonly __clientFacade: ClientFacade,
    private readonly __httpClient: HttpClient
  ) {}

  loadTablePage(params: PaginationQuery<QaResultPaginationParams>): Observable<PaginationQueryResult<QaTableResult>> {
    const url = this.__paginationService.generateURLWithQueryParamsAndPagination(
      params,
      API_ROUTES.qaChecklistChecks.results.list(this.__clientFacade.selectedClient?.slug)
    );
    return this.__httpClient
      .get<PaginationQueryResult<QaTableResult>>(url)
      .pipe(map((r) => ((r.result = plainToInstance(QaTableResult, r.result)), r)));
  }

  performQA(checklistId: number, data: QaResultPayloadType[]): Observable<void> {
    return this.__httpClient.post<void>(
      API_ROUTES.qaChecklistChecks.results.performQA(this.__clientFacade.selectedClient.slug, checklistId),
      data
    );
  }

  getResult(runId: number): Observable<QaResultDetails> {
    return this.__httpClient.get<QaResultDetails>(
      API_ROUTES.qaChecklistChecks.results.runDetails(this.__clientFacade.selectedClient.slug, runId)
    );
  }

  completeRun(runId: number): Observable<QaResultDetails> {
    return this.__httpClient.patch<QaResultDetails>(
      API_ROUTES.qaChecklistChecks.results.runDetails(this.__clientFacade.selectedClient.slug, runId),
      {
        status: 'COMPLETED'
      }
    );
  }

  getResultChecks(runId: number, params: PaginationQuery<QaResultChecksPaginationParams>): Observable<NgxTableDatasource<QaResultCheck>> {
    const url = this.__paginationService.generateURLWithQueryParamsAndPagination(
      params,
      API_ROUTES.qaChecklistChecks.results.runChecks(this.__clientFacade.selectedClient.slug, runId)
    );
    return this.__httpClient
      .get<NgxTableDatasource<QaResultCheck>>(url)
      .pipe(map((r) => ((r.result = plainToInstance(QaResultCheck, r.result)), r)));
  }

  updateResultCheck({
    runId,
    resultId,
    check_status
  }: {
    runId: number;
    resultId: number;
    check_status: QaResultCheckStatusType;
  }): Observable<QaResultCheck[]> {
    return this.__httpClient.patch<QaResultCheck[]>(
      API_ROUTES.qaChecklistChecks.results.runChecks(this.__clientFacade.selectedClient.slug, runId, resultId),
      { check_status }
    );
  }
}
