import { PlatformsEnum } from '@shared/platforms/enums/platforms.enum';
import { QaCheckType } from '@shared/qa-checklists/types/qa-check-type.type';

export class QaTableCheck {
  check_id: number;

  name: string;

  description?: string;

  business_unit_id?: number;

  business_unit_name?: string;

  levels: string[];

  type: QaCheckType;

  order?: number;

  platform_name?: PlatformsEnum;
}
