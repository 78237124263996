import { QaCheckType } from '@shared/qa-checklists/types/qa-check-type.type';

export class QaGovernanceItem {
  id: number;
  name: string;
  description: string;
  business_unit_name: string;
  business_unit_id: 0;
  levels: string[];
  type: Omit<QaCheckType, 'MANUAL'>;
}
