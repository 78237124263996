import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PaginationQuery, PaginationQueryResult } from '@core/models/search-query.model';
import { API_ROUTES } from '@environments/routes/api-routes';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { PaginationService } from '@shared/common/services/pagination.service';
import { QaChecklistPaginationParams } from '@shared/qa-checklists/models/qa-checklist-pagination-params.model';
import { QaChecklist } from '@shared/qa-checklists/models/qa-checklist.model';
import { plainToInstance } from 'class-transformer';
import { map, Observable } from 'rxjs';

@Injectable()
export class QaChecklistService {
  private __httpClient = inject(HttpClient);
  private __clientFacade = inject(ClientFacade);
  private __paginationService = inject(PaginationService);

  loadTablePage(params: PaginationQuery<QaChecklistPaginationParams>): Observable<PaginationQueryResult<QaChecklist>> {
    const url = this.__paginationService.generateURLWithQueryParamsAndPagination(
      params,
      API_ROUTES.qaChecklists.list(this.__clientFacade.selectedClient?.slug)
    );
    return this.__httpClient
      .get<PaginationQueryResult<QaChecklist>>(url)
      .pipe(map((r) => ((r.result = plainToInstance(QaChecklist, r.result)), r)));
  }

  getChecklist(id: number): Observable<QaChecklist> {
    return this.__httpClient
      .get<QaChecklist>(API_ROUTES.qaChecklists.get(this.__clientFacade.selectedClient?.slug, id))
      .pipe(map((r) => plainToInstance(QaChecklist, r)));
  }

  createChecklist({ platform_name, name, description }: Omit<QaChecklist, 'id'>): Observable<QaChecklist> {
    return this.__httpClient
      .post<QaChecklist>(API_ROUTES.qaChecklists.create(this.__clientFacade.selectedClient?.slug), {
        platform_name,
        name,
        ...(description ? { description } : {})
      })
      .pipe(map((r) => plainToInstance(QaChecklist, r)));
  }

  updateChecklist({ id, name, description }: { id: number } & Partial<QaChecklist>): Observable<QaChecklist> {
    return this.__httpClient
      .patch<QaChecklist>(API_ROUTES.qaChecklists.update(this.__clientFacade.selectedClient?.slug, id), { name, description })
      .pipe(map((r) => plainToInstance(QaChecklist, r)));
  }

  enableChecklist(qaChecklist: { id: number }): Observable<QaChecklist> {
    return this.__httpClient
      .patch<QaChecklist>(API_ROUTES.qaChecklists.update(this.__clientFacade.selectedClient?.slug, qaChecklist.id), {
        status: 'ENABLED'
      })
      .pipe(map((r) => plainToInstance(QaChecklist, r)));
  }

  archiveChecklist(qaChecklist: { id: number }): Observable<QaChecklist> {
    return this.__httpClient
      .patch<QaChecklist>(API_ROUTES.qaChecklists.update(this.__clientFacade.selectedClient?.slug, qaChecklist.id), {
        status: 'ARCHIVED'
      })
      .pipe(map((r) => plainToInstance(QaChecklist, r)));
  }
}
