import { PlatformLevel } from '@shared/common/models/platform-level.model';
import { PlatformsEnum } from '@shared/platforms/enums/platforms.enum';
import { QaChecklistStatus } from '@shared/qa-checklists/types/qa-checklist-status.type';
import { Type } from 'class-transformer';

export class QaChecklist {
  id: number;

  client: string;

  platform_name: PlatformsEnum;

  name: string;

  description?: string;

  status: QaChecklistStatus;

  @Type(() => PlatformLevel)
  levels: PlatformLevel[];

  creator?: string;

  @Type(() => Date)
  created_at?: Date;

  editor?: string;

  @Type(() => Date)
  updated_at?: Date;
}
