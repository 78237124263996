import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationQuery, PaginationQueryResult, SearchParams } from '@core/models/search-query.model';
import { API_ROUTES } from '@environments/routes/api-routes';
import { ClientFacade } from '@shared/clients/store/client.facade';
import { PaginationService } from '@shared/common/services/pagination.service';
import { QaAutomatedCheckDTO } from '@shared/qa-checklists/dto/qa-automated-check.dto';
import { QaManualCheckDTO } from '@shared/qa-checklists/dto/qa-manual-check.dto';
import { QaAutomatedCheck } from '@shared/qa-checklists/models/qa-automated-check.model';
import { QaCheckPaginationParams } from '@shared/qa-checklists/models/qa-check-pagination-params.model';
import { QaChecklist } from '@shared/qa-checklists/models/qa-checklist.model';
import { QaGovernanceItem } from '@shared/qa-checklists/models/qa-governance-item.models';
import { QaManualCheck } from '@shared/qa-checklists/models/qa-manual-check.model';
import { QaTableCheck } from '@shared/qa-checklists/models/qa-table-check.model';
import { QaCheckType } from '@shared/qa-checklists/types/qa-check-type.type';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';

@Injectable()
export class QaCheckService {
  constructor(
    private readonly __paginationService: PaginationService,
    private readonly __clientFacade: ClientFacade,
    private readonly __httpClient: HttpClient
  ) {}

  loadTablePage(params: PaginationQuery<QaCheckPaginationParams>): Observable<PaginationQueryResult<QaTableCheck>> {
    const url = this.__paginationService.generateURLWithQueryParamsAndPagination(
      params,
      API_ROUTES.qaChecklistChecks.list(this.__clientFacade.selectedClient?.slug, params.searchParams.checklist_id)
    );
    return this.__httpClient
      .get<PaginationQueryResult<QaTableCheck>>(url)
      .pipe(map((r) => ((r.result = plainToInstance(QaTableCheck, r.result)), r)));
  }

  loadGovernanceItems(qaChecklistId: number, params: PaginationQuery<SearchParams>): Observable<PaginationQueryResult<QaGovernanceItem>> {
    const url = this.__paginationService.generateURLWithQueryParamsAndPagination(
      params,
      API_ROUTES.qaChecklistChecks.governanceItems(this.__clientFacade.selectedClient?.slug, qaChecklistId)
    );
    return this.__httpClient
      .get<PaginationQueryResult<QaGovernanceItem>>(url)
      .pipe(map((r) => ((r.result = plainToInstance(QaGovernanceItem, r.result)), r)));
  }

  getManualCheck(checklistOrId: QaChecklist | number, checkId: number): Observable<QaManualCheck> {
    if (checklistOrId instanceof QaChecklist) {
      checklistOrId = checklistOrId.id;
    }
    return this.__httpClient
      .get<QaManualCheck>(API_ROUTES.qaChecklistChecks.getManual(this.__clientFacade.selectedClient?.slug, checklistOrId, checkId))
      .pipe(map((r) => plainToInstance(QaManualCheck, r)));
  }

  createManualCheck(checklistOrId: QaChecklist | number, dto: QaManualCheckDTO): Observable<QaManualCheck> {
    if (checklistOrId instanceof QaChecklist) {
      checklistOrId = checklistOrId.id;
    }

    delete dto.id;

    return this.__httpClient
      .post<QaManualCheck>(API_ROUTES.qaChecklistChecks.createManual(this.__clientFacade.selectedClient?.slug, checklistOrId), dto)
      .pipe(map((r) => plainToInstance(QaManualCheck, r)));
  }

  updateManualCheck(checklistOrId: QaChecklist | number, dto: Omit<QaManualCheckDTO, 'id'> & { id: number }): Observable<QaManualCheck> {
    if (checklistOrId instanceof QaChecklist) {
      checklistOrId = checklistOrId.id;
    }

    const checkId = dto.id;
    delete dto.id;
    delete dto.platform_name;

    return this.__httpClient
      .patch<QaManualCheck>(
        API_ROUTES.qaChecklistChecks.updateManual(this.__clientFacade.selectedClient?.slug, checklistOrId, checkId),
        dto
      )
      .pipe(map((r) => plainToInstance(QaManualCheck, r)));
  }

  createAutomatedCheck(checklistOrId: QaChecklist | number, check: QaAutomatedCheckDTO): Observable<QaAutomatedCheck> {
    if (checklistOrId instanceof QaChecklist) {
      checklistOrId = checklistOrId.id;
    }
    return this.__httpClient
      .post<QaAutomatedCheck>(API_ROUTES.qaChecklistChecks.createAutomated(this.__clientFacade.selectedClient?.slug, checklistOrId), {
        check_id: check.check_id,
        type: check.type.toUpperCase()
      })
      .pipe(map((r) => plainToInstance(QaAutomatedCheck, r)));
  }

  reorderChecks(
    checklistOrId: QaChecklist | number,
    data: {
      offset: number;
      limit: number;
      checks: { check_id: number; type: QaCheckType }[];
    }
  ): Observable<void> {
    if (checklistOrId instanceof QaChecklist) {
      checklistOrId = checklistOrId.id;
    }
    return this.__httpClient.patch<void>(API_ROUTES.qaChecklistChecks.reorder(this.__clientFacade.selectedClient?.slug, checklistOrId), {
      offset: data.offset,
      limit: data.limit,
      checklist_checks: data.checks.map((ch) => ({ check_id: ch.check_id, type: ch.type.toUpperCase() }))
    });
  }

  removeCheck(checklistOrId: QaChecklist | number, check: QaTableCheck): Observable<void> {
    if (checklistOrId instanceof QaChecklist) {
      checklistOrId = checklistOrId.id;
    }
    const url = API_ROUTES.qaChecklistChecks.delete(this.__clientFacade.selectedClient?.slug, checklistOrId);
    return this.__httpClient.delete<void>(`${url}?check_id=${check.check_id}&type=${check.type.toUpperCase()}`);
  }
}
